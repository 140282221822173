export default function QrModal() {
  return (
    <div className="modal " id="QrModal">
      <div className="modal-dialog modal-sm-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="qr-modal-title d-flex align-items-start justify-content-between">
            <h2 className="mb-0 font-regular p-3">QR Code</h2>
          </div>

          <div className="QrCode-col d-flex align-items-center justify-content-center ">
            <h1>QR Code</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
