import { SearchNormal1 } from "iconsax-react";
import { Link } from "react-router-dom";

export default function AddPatient() {
  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="breadcrumb-col col-12 mb-32">
            <ul className="breadcrumb-list d-flex align-items-center gap-2">
              <li>
                <p className="link-color mb-0">Home Dashboard</p>
              </li>
              <li>{">"}</li>
              <li>
                <p className="mb-0"> Add Patient</p>
              </li>
            </ul>
          </div>
          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">Add Patient</h2>
          </div>

          <div className="col-12 patient-form-col mb-32">
            <div className="bg-white patient-form p-32">
              <form className="row text-start">
                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="FirstName"
                  />
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="LastName"
                  />
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of Birth"
                    name="Date of Birth"
                  />
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    Gender
                  </label>
                  <select class="form-select form-control">
                    <option selected disabled>Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    External ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="External ID"
                    name="External ID"
                  />
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                  />
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Phone Number"
                    name="Phone Number"
                  />
                </div>

                <div className="col-12 col-lg-3 mb-32">
                  <label htmlFor="email" className="form-label">
                    Status
                  </label>
                  <div className="switch-btn d-flex align-items-center justify-content-between">
                    <p className="mb-0">Active</p>

                    <div class="form-check form-switch mb-0 p-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mySwitch"
                        name="darkmode"
                        value="yes"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 textarea-col">
                  <label for="comment">Note</label>
                  <textarea
                    class="form-control"
                    placeholder="Note"
                    rows="5"
                    id="comment"
                    name="text"
                  ></textarea>
                </div>
              </form>
            </div>
          </div>

          <div className="col-12 d-flex form-button text-start gap-3">
            <Link to={"/"}>
              <button className=" btn btn2">Cancel</button>
            </Link>
            <Link to={"/ExerciseProgram"} className="btn">
              <button className="btn"> + Add Patient</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
