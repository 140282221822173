import { Add, SearchNormal1, Status, ArrowLeft, ArrowRight } from "iconsax-react";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import QrModal from "../../components/QrModal";
import { Container } from "./Container";
import Example from "./example";

// import Card from "./card";

export default function ExerciseProgram() {
  const [arr, setArray] = useState([]);
  const [leftSide, setLeftSide] = useState("");
  const [rightSide, setRightSide] = useState("");
  const [indexx, setIndex] = useState(1);

  const [selected, setSelected] = useState([]);
  // const
  const [setsData, setSetsData] = useState([
    {
      id: 1,
      setName: "Feet Together",
      setStatus: "static",
      url: "../../assets/images/FeetTogether.png",
      setSide: false,
      setLeftSide: "L",
      setRightSide: "R",
      activeR: false,
      activeL: false,
      rating: false,
    },
    {
      id: 2,
      setName: "Tandem Stance",
      setStatus: "static",
      url: "../../assets/images/TandemSupported.png",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      activeR: false,
      activeL: false,
      rating: false,
    },
    {
      id: 3,
      setName: "SLSR Hold",
      setStatus: "static",
      url: "../../assets/images/SLSR_left.png",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      activeR: false,
      activeL: false,
      rating: false,
    },
    {
      id: 4,
      setName: "Back Leg Raises",
      setStatus: "static",
      url: "../../assets/images/ForwardReach_left.png",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      activeR: false,
      activeL: false,
      rating: false,
    },
  ]);

  let array = [];
  const selectCardHandler = (id) => {
    const indexNo = selected.indexOf(id);
    if (indexNo > -1) {
      const data = [...selected];
      setSelected(data.splice(indexNo, 1)); // 2nd parameter means remove one item only
      setSelected(data);
    } else {
      setSelected([...selected, id]);
      // setArray([...arr , setsData[index]])
    }
  };
  const submithandler = () => {
    const data = setsData?.filter((value) => selected.includes(value.id));
    setArray(data);
  };

  const sideSelection = (index, side) => {
    const abcc = [...setsData];
    if (side === "R") {
      abcc[index].activeR = !abcc[index].activeR;
      setSetsData(abcc);
    } else if (side === "L") {
      abcc[index].activeL = !abcc[index].activeL;
      setSetsData(abcc);
    }
  };
  const ratingHandler = (id) => {
    debugger;
    const cloneDataSet = [...arr];
    const index = cloneDataSet.findIndex((item) => item.id === id);
    cloneDataSet[index].rating = !cloneDataSet[index].rating;
    setArray(cloneDataSet);
  };
  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="breadcrumb-col d-flex justify-content-between  col-12 mb-32">
            <ul className="breadcrumb-list d-flex align-items-center gap-2">
              <li>
                <p className="link-color mb-0">Home Dashboard</p>
              </li>
              <li>{">"}</li>
              <li>
                <p className="mb-0"> Home Exercise Program</p>
              </li>
            </ul>
            {arr.length > 0 && (
              <div className="qr-btn-col d-flex gap-3">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#QrModal"
                >
                  Generate QR Code
                </button>                
                <button className="btn" disabled>
                  Save Changes
                </button>              
              </div>
            )}
          </div>

          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">Phil Coulson</h2>
          </div>

          <div className="col-12 info-card-col mb-32">
            <ul className="d-flex align-items-center gap-3">
              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Status size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Patients Status</p>
                    <h4 className="mb-0 mt-2 status-color">Active</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          {arr.length > 0 && (
            <DndProvider backend={HTML5Backend}>
              <Container setsData={arr} ratingHandler={ratingHandler} />
              <Example />
            </DndProvider>
          )}

          <div className="col-12 col-lg-4 col-xl-3 addexercise-card mb-32">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              className="addexercise-card-desc border-0  d-flex align-items-center justify-content-center  bg-white "
            >
              <Add size="64" color="#3b82f6" />
            </button>
          </div>

          {arr.length > 0 && (
            <div className="col-12 text-start add-questionnaire-col d-flex flex-column mb-3">
              <h3 className="mb-32">Post Questionnaire</h3>

              <div className="question-col p-3 mb-3">
                <h5>Confidence Level</h5>
                <p className="mb-0 slate-500">1-10 scale</p>
              </div>
              <div className="question-col p-3 mb-3">
                <h5>Confidence Level</h5>
                <p className="mb-0 slate-500">1-10 scale</p>
              </div>

              <div className="col-12 add-poc-btn-col">
                <button type="button" className="btn btn2 w-100 py-2"><Add size="30" color="#000" /></button></div>
             
            </div>
           )} 
        </div>

        <div className="modal add-exercise-modal" id="myModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <ul className="nav nav-tabs  main-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#home"
                  >
                    Sets
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                    Exercises
                  </a>
                </li>
              </ul>

              <div className="modal-search position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Something..."
                />
                <SearchNormal1
                  size="21"
                  color="#64748b"
                  className="search-icon"
                />
              </div>
              {/* Tab panes */}
              <div className="tab-content">
                <div className="tab-pane active" id="home">
                  <ul className="nav nav-tabs sub-tabs gap-3">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#foundation"
                      >
                        Foundational
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#basic"
                      >
                        Basic
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="foundation">
                      <ul className="modal-exercise-card">
                        {setsData.map((data, index) => (
                          <li key={data.id}>
                            <div className="text-start exercise-title">
                              <h6 className="mb-0">{data.setName}</h6>
                              <p
                                className="mb-0 text-capitalize "
                                style={{
                                  color:
                                    data.setStatus === "dynamic"
                                      ? "#EF4444"
                                      : "#84CC16",
                                }}
                              >
                                {data.setStatus}
                              </p>
                            </div>
                            <img
                              className=""
                              alt="exercise-img"
                              src={data.url}
                            />
                            {data.setSide === true && (
                              <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                <span
                                  className={`${
                                    data.activeL === true ? "active" : ""
                                  } cursor-pointer`}
                                  onClick={() => sideSelection(index, "L")}
                                >
                                  L
                                </span>
                                <span
                                  className={`${
                                    data.activeR === true ? "active" : ""
                                  } cursor-pointer`}
                                  onClick={() => sideSelection(index, "R")}
                                >
                                  R
                                </span>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-pane  fade" id="basic">
                      <ul className="modal-exercise-card">
                        {setsData.map((data, index) => (
                          <li>
                            <div className="text-start exercise-title">
                              <h6 className="mb-0">{data.setName}</h6>
                              <p
                                className="mb-0 text-capitalize "
                                style={{
                                  color:
                                    data.setStatus === "dynamic"
                                      ? "#EF4444"
                                      : "#84CC16",
                                }}
                              >
                                {data.setStatus}
                              </p>
                            </div>
                            <img
                              className=""
                              alt="exercise-img"
                              src={data.url}
                            />
                            {data.setSide === true && (
                              <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                <span
                                  className={`${
                                    data.activeL === true ? "active" : ""
                                  } cursor-pointer`}
                                  onClick={() => sideSelection(index, "L")}
                                >
                                  L
                                </span>
                                <span
                                  className={`${
                                    data.activeR === true ? "active" : ""
                                  } cursor-pointer`}
                                  onClick={() => sideSelection(index, "R")}
                                >
                                  R
                                </span>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="tab-pane exercises-tab-pane fade" id="menu1">
                  <ul className="nav nav-tabs sub-tabs gap-3">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#Easy"
                      >
                        Easy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Medium"
                      >
                        Medium
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Advanced"
                      >
                        Advanced
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#All">
                        All
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane  active" id="Easy">
                      <ul className="modal-exercise-card select-exercise-list">
                        {setsData.map((data, index) => (
                          <li
                            className={` position-relative  ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                            key={data.id}
                          >
                            <div
                              className="absolute-col cursor-pointer"
                              onClick={() => selectCardHandler(data.id)}
                            ></div>
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                            </div>
                            {data.setSide === true && (
                              <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                <button
                                  className={`${
                                    selected.includes(data.id) &&
                                    data.activeL === true
                                      ? "active"
                                      : ""
                                  } cursor-pointer`}
                                  onClick={() =>
                                    selected.includes(data.id) &&
                                    sideSelection(index, "L")
                                  }
                                  disabled={index === 3 && true}
                                >
                                  L
                                </button>
                                <button
                                  className={`${
                                    selected.includes(data.id) &&
                                    data.activeR === true
                                      ? "active"
                                      : ""
                                  } cursor-pointer`}
                                  onClick={() =>
                                    selected.includes(data.id) &&
                                    sideSelection(index, "R")
                                  }
                                >
                                  R
                                </button>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                      <div className="col-12 pagination-col d-flex justify-content-center  mt-4">
              <ul class="pagination pagination-sm ">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowLeft size={14} color="#020617" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowRight size={14} color="#020617" />
                  </a>
                </li>
              </ul>
            </div>
                    </div>
                    <div className="tab-pane  fade" id="Medium">
                      <ul className="modal-exercise-card select-exercise-list">
                        {setsData.map((data) => (
                          <li
                            className={`cursor-pointer ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                            onClick={() => selectCardHandler(data.id)}
                            key={data.id}
                          >
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                              {data.setSide === true && (
                                <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                  <span>L</span>
                                  <span className="active">R</span>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-pane  fade" id="Advanced">
                      <ul className="modal-exercise-card select-exercise-list">
                        {setsData.map((data) => (
                          <li
                            className={`cursor-pointer ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                            onClick={() => selectCardHandler(data.id)}
                            key={data.id}
                          >
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                              {data.setSide === true && (
                                <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                  <span>L</span>
                                  <span className="active">R</span>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-pane  fade" id="All">
                      <ul className="modal-exercise-card select-exercise-list">
                        {setsData.map((data) => (
                          <li
                            className={`cursor-pointer ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                            onClick={() => selectCardHandler(data.id)}
                            key={data.id}
                          >
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                              {data.setSide === true && (
                                <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                  <span>L</span>
                                  <span className="active">R</span>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center modal-btn gap-3">
                <button
                  type="button"
                  className=" btn btn2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  data-bs-dismiss="modal"
                  className="btn "
                  onClick={() => submithandler()}
                >
                  + Add HEP
                </button>
              </div>
            </div>
          </div>
        </div>

        <QrModal />
      </div>
    </>
  );
}
