import {
  Calendar,
  Clock,
  Flash,
  Health,
  Status,
} from "iconsax-react";


export default function PatientPerformance() {
  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="breadcrumb-col d-flex justify-content-between  col-12 mb-32">
            <ul className="breadcrumb-list d-flex align-items-center gap-2">
              <li>
                <p className="link-color mb-0">Home Dashboard</p>
              </li>
              <li>{">"}</li>
              
              <li>
                <p className="mb-0">Patient Performance</p>
              </li>
            </ul>
           
          </div>

          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">Phil Coulsan</h2>
          </div>

          <div className="col-12 info-card-col mb-32">
            <ul className="d-flex align-items-center gap-3">
              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Status size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Patient Status</p>
                    <h4 className="mb-0 mt-2 ">...</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Health size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Trending</p>
                    <h4 className="mb-0 mt-2 ">...</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Calendar size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">HEP 30 Days</p>
                    <h4 className="mb-0 mt-2 ">...</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Flash size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">HEP All Time</p>
                    <h4 className="mb-0 mt-2 ">...</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Clock size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Last Assessment</p>
                    <h4 className="mb-0 mt-2 ">...</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12 add-poc-btn-col">
<button type="button" className="btn btn2 w-100">+ Create HEP</button>
          </div>

        </div>
      </div>
    </>
  );
}
