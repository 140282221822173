import {
  ArrowCircleDown,
  Calendar,
  Clock,
  Flash,
  Health,
  MoreCircle,
  Status,
  ArrowLeft,
  ArrowRight,
} from "iconsax-react";
import { DateRange } from "react-date-range";
import { useState } from "react";
import { Link } from "react-router-dom";
import Chart from "../../components/chart.js";
import { Star1 } from 'iconsax-react'

export default function PatientPerformance2() {
  const [from, setForm] = useState("");
  const [collapseShow , setCollapseShow] = useState('')
  const [ariaexpand , setAriaexpand] = useState('false')
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const expandAll = ()=> {
    setCollapseShow('show')
    setAriaexpand('true')
  }
  const collapseAll = ()=> {
    setCollapseShow('false')
    setAriaexpand('fasle')
  }

  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="breadcrumb-col d-flex justify-content-between  col-12 mb-32">
            <ul className="breadcrumb-list d-flex align-items-center gap-2">
              <li>
                <p className="link-color mb-0">Home Dashboard</p>
              </li>
              <li>{">"}</li>
              <li>
                <p className="mb-0">Patient Performance</p>
              </li>
            </ul>

            <div className="search-col d-flex align-items-center gap-3 ps-md-4">
              <div className="dropdown date-range-selector">
                <input
                  value={
                    state[0].endDate === null || state[0].startDate === null
                      ? from
                      : ` From ${new Date(
                          state[0].startDate
                        ).getDate()} To ${new Date(state[0].endDate).getDate()}`
                  }
                  className="text-start  dropdown-toggle form-control"
                  data-bs-toggle="dropdown"
                  type="text"
                  placeholder="Last 60 days"
                  readOnly
                />
                <p>Activity: </p>
                <ArrowCircleDown
                  className="arrow-down-icon"
                  size="21"
                  color="#64748b"
                />

                <ul className="dropdown-menu">
                  <li
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setForm("Last 60 Days");
                    }}
                  >
                    Last 30 Days
                  </li>
                  <li
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setForm("Last 45 Days");
                    }}
                  >
                    Last 45 Days
                  </li>
                  <li
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setForm("Last 60 Days");
                    }}
                  >
                    Last 60 Days
                  </li>
                  <li data-bs-toggle="modal" data-bs-target="#myModal3">
                    Custom
                  </li>
                </ul>
              </div>

              
            </div>
          </div>

          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">Phil Coulson</h2>
          </div>

          <div className="col-12 info-card-col mb-32">
            <ul className="d-flex align-items-center gap-3">
              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Status size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Patient Status</p>
                    <h4 className="mb-0 mt-2 status-color">Active</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Health size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Trending</p>
                    <h4 className="mb-0 mt-2 status-color">+ 8</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Calendar size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Sessions Completed</p>
                    <h4 className="mb-0 mt-2 ">3</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Flash size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Sessions Days</p>
                    <h4 className="mb-0 mt-2 ">3</h4>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <Clock size="24" color="#3b82f6" />
                  </span>

                  <div className=" text-start ps-3">
                    <p className="slate-500 mb-0">Last Assessment</p>
                    <h4 className="mb-0 mt-2 ">Oct 29</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12 table-col">
            <div className="table-data-col bg-white table-responsive ">
              <table className="table patient-performance-table">
              
                <tbody>
                  <tr>
                <td className="">Date</td>
                    <td>SBT Score</td>
                    <td>SBT Baseline Change</td>
                    <td className="text-end expand-btn">
                      <button onClick={()=> expandAll()}>Expand All </button>
                      <button onClick={()=> collapseAll()}>Collapse All</button>
                      </td>
                  </tr>

                  <tr data-bs-toggle="collapse" className="collpase-tr collapsed" data-bs-target="#demo" aria-expanded={ariaexpand}>
                    <td>Oct 29th 2023</td>
                    <td>61</td>
                    <td><span className="improve-badge">8</span></td>
                    <td className="text-end">
                      <ArrowCircleDown
                        className="arrow-down-icon"
                        size="21"
                        color="#64748b"
                      />
                    </td>
                  </tr>

                  <tr id="demo" className={`collapse ${collapseShow}`}>
                    {/* <td className="p-0 border-0"></td> */}
                    <td  colSpan={4} className="p-0 border-0">
                  <table className="table mb-0 collapsible-table">
                <thead>
                  <tr>
                    <th className="">Exercise</th>
                    <th>HEP Score</th>
                    <th>% Change Baseline</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                <td>Tandem Stance (Left)</td>
                    <td>64</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                      <span className="improve-badge">2</span>
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      
                      </div></td>
                      </tr>
                      <tr>
                <td>Single Leg Side (Left)  <Star1 className='cursor-pointer' color="#facc15" size={20} variant="Bold" align="left" /></td>
                    <td>40</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                      <span className="improve-badge">8</span>
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>

                      <tr>
                <td>Single Leg Side Raise (Right)</td>
                    <td>34</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                    <span className="improve-badge">14</span> 
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <p className="mb-0">Post Assessment Questionnaire - Exertion : 8</p>
                        </td>
                      </tr>
                  </tbody>
                  </table>
                  </td>
                    
                  </tr>

                  <tr data-bs-toggle="collapse" className="collpase-tr collapsed" data-bs-target="#demo" aria-expanded={ariaexpand}>
                    <td>Oct 24th 2023</td>
                    <td>40</td>
                    <td><span className="not-improve">2</span></td>
                    <td className="text-end">
                      <ArrowCircleDown
                        className="arrow-down-icon"
                        size="21"
                        color="#64748b"
                      />
                    </td>
                  </tr>

                  <tr id="demo" className={`collapse ${collapseShow}`}>
                    {/* <td className="p-0 border-0"></td> */}
                    <td  colSpan={4} className="p-0 border-0">
                  <table className="table mb-0 collapsible-table">
                <thead>
                  <tr>
                    <th className="">Exercise</th>
                    <th>HEP Score</th>
                    <th>% Change Baseline</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                <td>Tandem Stance (Left)</td>
                    <td>66</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                      <span className="improve-badge">4</span>
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      
                      </div></td>
                      </tr>
                      <tr>
                <td>Single Leg Side Raise (Left) <Star1 className='cursor-pointer' color="#facc15" size={20} variant="Bold" align="left" /></td>
                    <td>32</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                      <span className="not-improve">2</span>
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>

                      <tr>
                <td>Single Leg Side Raise (Right)</td>
                    <td>22</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                      <span className="improve-badge">2</span> 
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <p className="mb-0">Post Assessment Questionnaire - Exertion : 8</p>
                        </td>
                      </tr>
                  </tbody>
                  </table>
                  </td>
                    
                  </tr>

                  <tr data-bs-toggle="collapse" className="collpase-tr collapsed" data-bs-target="#demo2" aria-expanded={ariaexpand}>
                    <td>Oct 14th 2023</td>
                    <td>34</td>
                    <td><span className="bg-primary text-white px-2 py-1" style={{borderRadius: "16px", fontSize: "10px"}}>Baseline</span></td>
                    <td className="text-end">
                      <ArrowCircleDown
                        className="arrow-down-icon"
                        size="21"
                        color="#64748b"
                      />
                    </td>
                  </tr>

                  <tr id="demo2" className={`collapse ${collapseShow}`}>
                    {/* <td className="p-0 border-0"></td> */}
                    <td  colSpan={4} className="p-0 border-0">
                  <table className="table mb-0 collapsible-table">
                <thead>
                  <tr>
                    <th className="">Exercise</th>
                    <th>HEP Score</th>
                    <th>% Change Baseline</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                <td>Tandem Stance (Left)</td>
                    <td>62</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                    <span className="bg-primary text-white px-2 py-1" style={{borderRadius: "16px", fontSize: "10px"}}>Baseline</span>
                     <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>
                      <tr>
                <td>Single Leg Side Raise (Left)  <Star1 className='cursor-pointer' color="#facc15" size={20} variant="Bold" align="left" /></td>
                    <td>34</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                      <span className="bg-primary text-white px-2 py-1" style={{borderRadius: "16px", fontSize: "10px"}}>Baseline</span>
                      <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>
                      <tr>
                <td>Single Leg Side Raise (Right)</td>
                    <td>20</td>
                    <td><div className="d-flex p-0  align-items-center justify-content-between ">
                    <span className="bg-primary text-white px-2 py-1" style={{borderRadius: "16px", fontSize: "10px"}}>Baseline</span>
                     <div className="dropdown">
                  <span
                    type="button"
                    className="dropdown-toggle d-flex align-items-center  "
                    data-bs-toggle="dropdown"
                  >
                   
                   <MoreCircle size="21" color="#64748b" />
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModal2">
                        View Plot
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="#">
                        Change Baseline
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                      </div></td>
                      </tr>
                      
                      <tr>
                        <td colSpan={3}>
                          <p className="mb-0">Post Assessment Questionnaire - Exertion : 8</p>
                        </td>
                      </tr>
                  </tbody>
                  </table>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-12 pagination-col d-flex justify-content-end  mt-4">
              <ul class="pagination pagination-sm ">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowLeft size={14} color="#020617" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowRight size={14} color="#020617" />
                  </a>
                </li>
              </ul>
            </div>
        </div>

        <div className="modal " id="myModal3">
        <div className="modal-dialog calendar-modal-col modal-dialog-centered">
          <div className="modal-content ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </div>
        </div>
      </div>

      <div className="modal chartmodal" id="myModal2">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="chartmodal-col">
              <div className="chart-modal-title d-flex align-items-start justify-content-between">
                <div className="text-start">
                  <h5 className="mb-1 font-regular">
                    Plot of Home Exercise Program
                  </h5>
                  <p className="mb-0">Last 60 Days</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <ellipse
                    cx="12"
                    cy="12.0945"
                    rx="12"
                    ry="12.0284"
                    fill="#BDBDBD"
                  />
                  <rect
                    x="10"
                    y="9.42151"
                    width="3.99999"
                    height="10.6919"
                    rx="2"
                    fill="white"
                  />
                  <rect
                    x="10"
                    y="4.07556"
                    width="3.99999"
                    height="4.00945"
                    rx="2"
                    fill="white"
                  />
                </svg>
              </div>
              <Chart />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
