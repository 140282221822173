import {
  Edit2,
  SearchNormal1,
  Trash,
  ArrowLeft,
  ArrowRight,
  Star1,
} from "iconsax-react";
import { useState } from "react";
export default function Settings() {
  const [arr, setArray] = useState([]);

  const [selected, setSelected] = useState([]);

  const [setsData , setSetsData] = useState([
    {
      id: 1,
      setName: "Sit-to-stand",
      setStatus: "dynamic",
      url: "../../assets/images/STS.png",
      setSide: false,
      setLeftSide: "L",
      setRightSide: "R",
      activeR:false,
      activeL:false,
    },
    {
      id: 2,
      setName: "Single Leg SR Hold",
      setStatus: "static",
      url: "../../assets/images/SLSR_left.png",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      activeR:false,
      activeL:false,
    },
    {
      id: 3,
      setName: "Forward Reach",
      setStatus: "static",
      url: "../../assets/images/ForwardReach_left.png",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      activeR:false,
      activeL:false,
    },
    {
      id: 4,
      setName: "Side Stepping",
      setStatus: "dynamic",
      url: "../../assets/images/MonsterWalk.png",
      setSide: false,
      setLeftSide: "L",
      setRightSide: "R",
      activeR:false,
      activeL:false,
    },
  ]);

  const tableData = [
    {
      CustomSetName: "Foundational",
      NumberofExercisesIncluded: "2 ",
    },
    {
      CustomSetName: "Basic",
      NumberofExercisesIncluded: "2",
    },
  ];

  const exerciseData = [
    {
      url: "../../assets/images/STS.png",
      Exercise: "Sit-to-stand",
      StaticorDynamic: "Dynamic",
      Level: "easy",
    },
    {
      url: "../../assets/images/ForwardReach_left.png",
      Exercise: "Forward Reach",
      StaticorDynamic: "Static",
      Level: "easy",
    },
  ];

  let array = [];
  const selectCardHandler = (id) => {
    const indexNo = selected.indexOf(id);
    if (indexNo > -1) {
      const data = [...selected];
      setSelected(data.splice(indexNo, 1)); // 2nd parameter means remove one item only
      setSelected(data);
    } else {
      setSelected([...selected, id]);
      // setArray([...arr , setsData[index]])
    }

    // console.log(data, "checking data");
    // else{
    //   setSelected([...selected , index]);
    //   console.log(selected ,'selected')

    // }
    // setSelectedCard("selected-exercise")

    console.log("array", array);
  };
  const submithandler = () => {
    const data = setsData?.filter((value) => selected.includes(value.id));

    setArray(data);
  };

  const sideSelection =(index , side)=> {
    const abcc =[...setsData];
    if(side === 'R'){
      abcc[index].activeR= !abcc[index].activeR;
      setSetsData(abcc);
    }
    else if (side === 'L'){
      abcc[index].activeL= !abcc[index].activeL;
      setSetsData(abcc);
    }
    
  }

  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">Settings</h2>

            <button type="button" className="btn">
              Save Changes
            </button>
          </div>

          <div className="col-12 patient-form-col mb-32">
            <div className="bg-white patient-form p-32">
              <form className="row text-start setting-form">
                <div className="col-12 col-lg-4 setting-col-form">
                  <label htmlFor="email" className="form-label">
                    Name of Clinic
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Booguu Co. Ltd."
                    name="Name of Clinic"
                  />
                </div>

                <div className="col-12 col-lg-4 setting-col-form">
                  <label htmlFor="email" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    name="Address"
                  />
                </div>
                <div className="col-12 col-lg-4 setting-col-form">
                  <label htmlFor="email" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Phone Number"
                    name="number"
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="col-12 sets-settings-col mb-32">
            <div className="sub-title d-flex align-items-center justify-content-between mb-32">
              <h4 className="mb-0">Sets</h4>
              <button
                className="btn btn2"
                data-bs-toggle="modal"
                data-bs-target="#myModal"
              >
                + Add Sets
              </button>
            </div>

            <div className="table-data-col sets-settings-table bg-white table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="">Custom Set Name</th>
                    <th>Number of Exercises Included</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.CustomSetName}</td>
                      <td>{data.NumberofExercisesIncluded}</td>

                      <td>
                        <span className="d-flex align-items-center justify-content-end  gap-3 actions-col">
                          <button className="" data-bs-toggle="modal"
                data-bs-target="#myModal">
                            <Edit2 size="24" color="#64748b" />
                          </button>
                          <button className="">
                            <Trash size="24" color="#64748b" />
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="col-12 pagination-col d-flex justify-content-end  mt-4">
              <ul class="pagination pagination-sm ">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowLeft size={14} color="#020617" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowRight size={14} color="#020617" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 sets-settings-col">
            <div className="sub-title d-flex align-items-center justify-content-between mb-32">
              <h4 className="mb-0">Exercises</h4>
              <button
                className="btn btn2"
                data-bs-toggle="modal"
                data-bs-target="#myModal2"
              >
                + Add Exercises
              </button>
            </div>

            <div className="table-data-col sets-settings-table bg-white table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="">Exercise</th>
                    <th>Static or Dynamic</th>
                    <th>Level</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {exerciseData.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          className="me-2"
                          width="24"
                          height={"24"}
                          src={data.url}
                          alt="Exercise-img"
                        />
                        {data.Exercise}
                      </td>
                      <td>{data.StaticorDynamic}</td>
                      <td>{data.Level}</td>

                      <td>
                        <span className="d-flex align-items-center justify-content-end  gap-3 actions-col">
                          <button className="">
                            <Trash size="24" color="#64748b" />
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-12 pagination-col d-flex justify-content-end  mt-4">
              <ul class="pagination pagination-sm ">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowLeft size={14} color="#020617" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ArrowRight size={14} color="#020617" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="modal add-exercise-modal" id="myModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content overflow-hidden ">
              <div className="modal-search position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Something..."
                />
                <SearchNormal1
                  size="21"
                  color="#64748b"
                  className="search-icon"
                />
              </div>
              {/* Tab panes */}
              <div className="col-12 set-name-form text-start px-4 pt-3">
                  <label htmlFor="email" className="form-label slate-500">
                    Set Name
                  </label>
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="Set Name"
                    name="Set Name"
                  />
                </div>
              
                  <ul className="modal-exercise-card p-4">
                  {setsData.map((data, index) => (
                          <li  key={data.id}>
                            <div className="text-start d-flex align-items-center justify-content-between  exercise-title">
                              <div className="">
                              <h6 className="mb-0">{data.setName}</h6>
                              <p
                                className="mb-0 text-capitalize "
                                style={{
                                  color:
                                    data.setStatus === "dynamic"
                                      ? "#EF4444"
                                      : "#84CC16",
                                }}
                              >
                                {data.setStatus}
                              </p>
                              </div>
                              <Star1 className='cursor-pointer' color="#64748b" size={20} variant="Bold" />
                            </div>
                            <img
                              className=""
                              alt="exercise-img"
                              src={data.url}
                            />
                            {data.setSide === true && (
                              <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                <span className={`${data.activeL === true ? 'active' : ''} cursor-pointer`} onClick={()=> sideSelection(index , 'L')}>L</span>
                                <span className={`${data.activeR === true ? 'active' : ''} cursor-pointer`} onClick={()=> sideSelection(index , 'R')}>R</span>
                              </div>
                            )}
                          </li>
                        ))}
                  </ul>
                

              <div className="d-flex align-items-center modal-btn gap-3">
                <button
                  type="button"
                  className=" btn btn2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  data-bs-dismiss="modal"
                  className="btn "
                  // onClick={() => submithandler()}
                >
                  + Add Sets
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal add-exercise-modal" id="myModal2">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content overflow-hidden ">
              <div className="modal-search position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Something..."
                />
                <SearchNormal1
                  size="21"
                  color="#64748b"
                  className="search-icon"
                />
              </div>

              <ul className="nav nav-tabs sub-tabs gap-3 mb-0 p-4 pb-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#Easy"
                  >
                    Easy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#Medium">
                    Medium
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#Advanced">
                    Advanced
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#All">
                    All
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane  active" id="Easy">
                  <ul className="modal-exercise-card select-exercise-list">
                  {setsData.map((data , index) => (
                          <li
                            className={` position-relative  ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                           
                            key={data.id}
                          >
                            <div className="absolute-col cursor-pointer"
                             onClick={() => selectCardHandler(data.id)}
                            ></div>
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                            </div>
                            {data.setSide === true && (
                                  <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                    <button className={`${(selected.includes(data.id) && data.activeL === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'L')}>L</button>
                                    <button  className={`${(selected.includes(data.id) && data.activeR === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'R')}>R</button>
                                  </div>
                               
                              )}

                          </li>
                        ))}
                  </ul>
                </div>
                <div className="tab-pane  fade" id="Medium">
                  <ul className="modal-exercise-card select-exercise-list">
                  {setsData.map((data , index) => (
                          <li
                            className={` position-relative  ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                           
                            key={data.id}
                          >
                            <div className="absolute-col cursor-pointer"
                             onClick={() => selectCardHandler(data.id)}
                            ></div>
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                            </div>
                            {data.setSide === true && (
                                  <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                    <button className={`${(selected.includes(data.id) && data.activeL === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'L')}>L</button>
                                    <button  className={`${(selected.includes(data.id) && data.activeR === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'R')}>R</button>
                                  </div>
                               
                              )}

                          </li>
                        ))}
                  </ul>
                </div>
                <div className="tab-pane  fade" id="Advanced">
                  <ul className="modal-exercise-card select-exercise-list">
                  {setsData.map((data , index) => (
                          <li
                            className={` position-relative  ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                           
                            key={data.id}
                          >
                            <div className="absolute-col cursor-pointer"
                             onClick={() => selectCardHandler(data.id)}
                            ></div>
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                            </div>
                            {data.setSide === true && (
                                  <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                    <button className={`${(selected.includes(data.id) && data.activeL === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'L')}>L</button>
                                    <button  className={`${(selected.includes(data.id) && data.activeR === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'R')}>R</button>
                                  </div>
                               
                              )}

                          </li>
                        ))}
                  </ul>
                </div>
                <div className="tab-pane  fade" id="All">
                  <ul className="modal-exercise-card select-exercise-list">
                  {setsData.map((data , index) => (
                          <li
                            className={` position-relative  ${
                              selected.includes(data.id) && "selected-exercise"
                            }`}
                           
                            key={data.id}
                          >
                            <div className="absolute-col cursor-pointer"
                             onClick={() => selectCardHandler(data.id)}
                            ></div>
                            <div className="d-flex flex-column gap-3">
                              <div className="text-start exercise-title">
                                <h6 className="mb-0">{data.setName}</h6>
                                <p
                                  className="mb-0 text-capitalize "
                                  style={{
                                    color:
                                      data.setStatus === "dynamic"
                                        ? "#EF4444"
                                        : "#84CC16",
                                  }}
                                >
                                  {data.setStatus}
                                </p>
                              </div>
                              <img
                                className=""
                                alt="exercise-img"
                                src={data.url}
                              />
                            </div>
                            {data.setSide === true && (
                                  <div className="d-flex align-items-center justify-content-between  side-select-btn">
                                    <button className={`${(selected.includes(data.id) && data.activeL === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'L')}>L</button>
                                    <button  className={`${(selected.includes(data.id) && data.activeR === true) ? 'active' : ''} cursor-pointer`} onClick={()=> selected.includes(data.id) && sideSelection(index , 'R')}>R</button>
                                  </div>
                               
                              )}

                          </li>
                        ))}
                  </ul>
                </div>
              </div>

              <div className="d-flex align-items-center modal-btn gap-3">
                <button
                  type="button"
                  className=" btn btn2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  data-bs-dismiss="modal"
                  className="btn "
                  // onClick={() => submithandler()}
                >
                  + Add Exercise
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
