
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import Dashboard from './pages/Dashboard';
import AddPatient from './pages/AddPatient'
import AllPatient from './pages/Patients';
import Settings from './pages/Settings';
import ExerciseProgram from './pages/ExerciseProgram';
import PatientProfile from './pages/PatientProfile';
import PatientPerformance from './pages/PatientPerformance';
import PatientPerformance2 from './pages/PatientPerformance/index2';

// import DragDrop from './pages/DragDrop';

function App() {
  return (
    <div className="App">
      
   <BrowserRouter>
        <div className="header-menu d-flex">
          <Header/>
        </div>
        <div className="d-flex align-items-start">
          <Sidebar/>

          <div className="main-wrapper w-100 ">
            <Routes>
          <Route exact path="/" element={<Dashboard />} />
               <Route exact path="/Patient" element={<AllPatient />} />
               <Route exact path="/AddPatient" element={<AddPatient/>} />
              <Route exact path="/Settings" element={<Settings />}/>

             <Route exact path="/ExerciseProgram" element={<ExerciseProgram />} />
              <Route exact path="/PatientProfile" element={<PatientProfile/>} />
              <Route exact path="/PatientPerformance" element={<PatientPerformance/>} />
              <Route exact path="/PatientPerformance2" element={<PatientPerformance2/>} /> 
              {/* <Route exact path="/DragDrop" element={<DragDrop/>} />  */}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
