import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Chart() {
  const navigate = useNavigate();
  const urlHandler = (url) => {
    switch (url) {
      case "PatientPerformance":
        navigate("/PatientPerformance");
        break;

      default:
        break;
    }
  };

  const [data, setData ] = useState({
    series: [
      {
        name: "Tandem Stance (Left)",
        data: [4, 4, 15, 21, 18, 22, 32],
      },
      {
        name: "Single Leg Side Raise (Left)",
        data: [2, 1, 4, 8, 15, 13, 13],
      },
      {
        name: "Single Leg Side Raise (Right)",
        data: [15, 45, 30, 35, 38, 48, 46],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'left', 
    },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Sep 1",
          "Sep 7",
          "Sep 14",
          "Sep 21",
          "Sep 28",
          "Oct 5",
          "Oct 12",
        ],
      },
    },
  });
  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="line"
        height={350}
      />
      <Link to={"/PatientPerformance"}  data-bs-dismiss="modal" onClick={()=> urlHandler("PatientPerformance")} className="chart-modal-link">
      Patient Peformance
      </Link>
    </div>
  );
}
