import {
  MoreCircle,
  SearchNormal1,
  ArrowLeft,
  ArrowRight,
} from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import Chart from "../../components/chart.js";

export default function AllPatient() {
  const tableData = [
    {
      patientName: "Fred Weasley",
      lastCheckIn: "48 minutes ago",
      dateEstab: "Sep 21, 2023",
      status: "Active",
      baselineChange: 27,
      improve: true,
    },
    {
      patientName: "Phil Coulson",
      lastCheckIn: "1 day ago",
      dateEstab: "Oct 14, 2023",
      status: "Active",
      baselineChange: 8,
      improve: true,
    },
    {
      patientName: "James Rhodes",
      lastCheckIn: "6 days ago",
      dateEstab: "May 21, 2023",
      status: "Active",
      baselineChange: 6,
      notImprove: true,
    },
    {
      patientName: "Virginia Potts",
      lastCheckIn: "9 days ago",
      dateEstab: "May 20, 2022",
      status: "Active",
      baselineChange: 40,
      improve: true,
    },
    {
      patientName: "Henry Granger",
      lastCheckIn: "9 days ago",
      dateEstab: "Sep 20, 2023",
      status: "Active",
      baselineChange: 16,
      improve: true,
    },
    {
      patientName: "Tony Stark",
      lastCheckIn: "11 days ago",
      dateEstab: "May 20, 2022",
      status: "Active",
      baselineChange: 26,
      notImprove: true,
    },
    {
      patientName: "Terence Xu",
      lastCheckIn: "12 days ago",
      dateEstab: "Oct 4, 2023",
      status: "Active",
      baselineChange: 0,
      notImprove: true,
    },
    {
      patientName: "Amy Walker",
      lastCheckIn: "102 days ago",
      dateEstab: "Dec 14, 2022",
      status: "Discharged",
      
    },
    {
      patientName: "Steven Stuart",
      lastCheckIn: "168 days ago",
      dateEstab: "Feb 20, 2022",
      status: "Discharged",      
    },
  ];

  const navigate = useNavigate();
  const urlHandler = (url) => {
    switch (url) {
      case "PatientProfile":
        navigate("/PatientProfile");
        break;
      case "ExerciseProgram":
        navigate("/ExerciseProgram");
        break;
      case "PatientPerformance":
        navigate("/PatientPerformance");
        break;
      case "PatientPerformance2":
        navigate("/PatientPerformance2");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">All Patients</h2>

            <div className="search-col nav-col d-flex align-items-center gap-3 ">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className="nav-link " data-bs-toggle="tab" href="#home">
                    Active Patients
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                    Discharged Patients
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#menu2"
                  >
                    All Patients
                  </a>
                </li>
              </ul>

              <div className="d-flex align-items-center gap-3 ">
                <div className="patient-search position-relative ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Patient..."
                />
                <SearchNormal1
                  size="21"
                  color="#64748b"
                  className="search-icon"
                />
</div>
<Link to={"/AddPatient"} className="btn">
                + Add Patient
              </Link>
              </div>
            </div>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade" id="home">
              <div className="col-12 table-col">
                <div className="table-data-col bg-white table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="">Patients Name</th>
                        <th>Date Established</th>
                        <th>Last Check In</th>
                        <th>Status</th>
                        <th>Baseline Change</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((data, index) => (
                        <tr key={index}>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.patientName}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.dateEstab}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.lastCheckIn}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.status}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            <span
                              className={`${
                                data.improve === true
                                  ? "improve-badge"
                                  : data.notImprove === true && "not-improve"
                              }`}
                            >
                              {data.baselineChange}
                            </span>
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            <div className="add-ellipses">{data.note}</div>
                          </td>
                          <td>
                            <span
                              className="cursor-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"
                            >
                              <MoreCircle size="32" color="#64748b" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 pagination-col d-flex justify-content-end  my-4">
                <ul class="pagination pagination-sm ">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      <ArrowLeft size={14} color="#020617" />
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      <ArrowRight size={14} color="#020617" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-pane fade" id="menu1">
              <div className="col-12 table-col">
                <div className="table-data-col bg-white table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                      <th className="">Patients Name</th>
                        <th>Date Established</th>
                        <th>Last Check In</th>
                        <th>Status</th>
                        <th>Baseline Change</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((data, index) => (
                        <tr key={index}>
                         <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.patientName}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.dateEstab}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.lastCheckIn}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.status}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            <span
                              className={`${
                                data.improve === true
                                  ? "improve-badge"
                                  : data.notImprove === true && "not-improve"
                              }`}
                            >
                              {data.baselineChange}
                            </span>
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            <div className="add-ellipses">{data.note}</div>
                          </td>
                          <td>
                            <span
                              className="cursor-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"
                            >
                              <MoreCircle size="32" color="#64748b" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 pagination-col d-flex justify-content-end  my-4">
                <ul class="pagination pagination-sm ">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      <ArrowLeft size={14} color="#020617" />
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      <ArrowRight size={14} color="#020617" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-pane  active" id="menu2">
              <div className="col-12 table-col">
                <div className="table-data-col bg-white table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                      <th className="">Patients Name</th>
                        <th>Date Established</th>
                        <th>Last Check In</th>
                        <th>Status</th>
                        <th>Baseline Change</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((data, index) => (
                        <tr key={index}>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.patientName}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.dateEstab}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.lastCheckIn}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.status}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            <span
                              className={`${
                                data.improve === true
                                  ? "improve-badge"
                                  : data.notImprove === true && "not-improve"
                              }`}
                            >
                              {data.baselineChange}
                            </span>
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            <div className="add-ellipses">{data.note}</div>
                          </td>
                          <td>
                            <span
                              className="cursor-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"
                            >
                              <MoreCircle size="32" color="#64748b" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 pagination-col d-flex justify-content-end  my-4">
                <ul class="pagination pagination-sm ">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      <ArrowLeft size={14} color="#020617" />
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      <ArrowRight size={14} color="#020617" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="col-12 table-col">
            <div className="table-data-col bg-white table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="">Patients Name</th>
                        <th>Date Established</th>
                        <th>Last Check In</th>
                        <th>Status</th>
                        <th>Baseline Change</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, index) => (
                    <tr key={index}>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.patientName}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.dateEstab}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.lastCheckIn}
                          </td>
                          <td data-bs-toggle="modal" data-bs-target="#myModal2">
                            {data.status}
                          </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        <span
                          className={`${
                            data.improve === true
                              ? "improve-badge"
                              : data.notImprove === true && "not-improve"
                          }`}
                        >
                          {data.baselineChange}
                        </span>
                      </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        <div className="add-ellipses">{data.note}</div>
                      </td>
                      <td>
                        <span
                          className="cursor-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                        >
                          <MoreCircle size="32" color="#64748b" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}
        </div>

        <div className="modal portal-option-modal fade" id="myModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-0">
                <ul>
                  <li>
                    <Link
                      to="/PatientProfile"
                      onClick={() => urlHandler("PatientProfile")}
                      data-bs-dismiss="modal"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <p className="mb-0">Profile</p>
                      <img
                        className="img-fluid"
                        src="../../assets/images/arrow-icon.svg"
                        alt="arrow-cicon"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ExerciseProgram"
                      onClick={() => urlHandler("ExerciseProgram")}
                      data-bs-dismiss="modal"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <p className="mb-0">Home Exercise Program Assignment</p>
                      <img
                        className="img-fluid"
                        src="../../assets/images/arrow-icon.svg"
                        alt="arrow-cicon"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/PatientProfile"
                      onClick={() => urlHandler("PatientPerformance2")}
                      data-bs-dismiss="modal"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <p className="mb-0">Patient Peformance</p>
                      <img
                        className="img-fluid"
                        src="../../assets/images/arrow-icon.svg"
                        alt="arrow-cicon"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="modal chartmodal" id="myModal2">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="chartmodal-col">
                <div className="chart-modal-title d-flex align-items-start justify-content-between">
                  <div className="text-start">
                    <h5 className="mb-1 font-regular">
                      Plot of Home Exercise Program
                    </h5>
                    <p className="mb-0">Last 60 Days</p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <ellipse
                      cx="12"
                      cy="12.0945"
                      rx="12"
                      ry="12.0284"
                      fill="#BDBDBD"
                    />
                    <rect
                      x="10"
                      y="9.42151"
                      width="3.99999"
                      height="10.6919"
                      rx="2"
                      fill="white"
                    />
                    <rect
                      x="10"
                      y="4.07556"
                      width="3.99999"
                      height="4.00945"
                      rx="2"
                      fill="white"
                    />
                  </svg>
                </div>
                <Chart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
