import {
  ArrowCircleDown,
  ArrowLeft,
  ArrowRight,
  MoreCircle,
  Refresh,
  SearchNormal1,
} from "iconsax-react";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Link, useNavigate } from "react-router-dom";
import Chart from "../../components/chart.js";
import RefreshModal from "../../components/RefreshModal/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function Dashboard() {
  const [from, setForm] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    console.log(state);
  }, []);
  const CustomToastWithLink = () => (
    <div className="d-flex align-items-center gap-4">
      <p className="mb-0">Data is out of date.</p>
      <a className="text-primary " href="/">
        Refresh
      </a>
    </div>
  );

  const notify = () => toast(CustomToastWithLink);

  //  const to = selectedDayRange.to.day

  console.log(state, "selectedDayRange");

  const navigate = useNavigate();
  const tableData = [
    {
      patientName: "Fred Weasley",
      lastCheckIn: "48 minutes ago",
      sessionsCompleted: "8",
      assessmentsDays: "30",
      baselineChange: 27,
      improve: true,
    },
    {
      patientName: "Phil Coulson",
      lastCheckIn: "1 day ago",
      sessionsCompleted: "3",
      assessmentsDays: "3",
      baselineChange: 8,
      improve: true,
    },
    {
      patientName: "James Rhodes",
      lastCheckIn: "6 days ago",
      sessionsCompleted: "8",
      assessmentsDays: "6",
      baselineChange: 6,
      notImprove: true,
    },
    {
      patientName: "Virginia Potts",
      lastCheckIn: "9 days ago",
      sessionsCompleted: "3",
      assessmentsDays: "2",
      baselineChange: 40,
      improve: true,
    },
    {
      patientName: "Henry Granger",
      lastCheckIn: "9 days ago",
      sessionsCompleted: "6",
      assessmentsDays: "6",
      baselineChange: 16,
      improve: true,
    },
    {
      patientName: "Tony Stark",
      lastCheckIn: "11 days ago",
      sessionsCompleted: "5",
      assessmentsDays: "2",
      baselineChange: 26,
      notImprove: true,
    },
    {
      patientName: "Terence Xu",
      lastCheckIn: "--",
      sessionsCompleted: "0",
      assessmentsDays: "0",
      baselineChange: 0,
      notImprove: true,
    },
  ];

  const urlHandler = (url) => {
    switch (url) {
      case "PatientProfile":
        navigate("/PatientProfile");
        break;
      case "ExerciseProgram":
        navigate("/ExerciseProgram");
        break;
      case "PatientPerformance":
        navigate("/PatientPerformance");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="container-fluid main-content">
        <div className="row">
          <div className="col-12 dashboard-title-col d-flex align-items-center justify-content-between mb-32">
            <h2 className="mb-0 font-regular">Home Dashboard</h2>

            <div className="search-col d-flex align-items-center gap-3 px-3">
              <div className="dropdown date-range-selector">
                <input
                  value={
                    state[0].endDate === null || state[0].startDate === null
                      ? from
                      : ` From ${new Date(state[0].startDate).toLocaleString('en-us',{month:'short', day: 'numeric', year:'numeric'})} To ${new Date(state[0].endDate).toLocaleString('en-us',{month:'short', day: 'numeric', year:'numeric'})}`
                  }
                  className="text-start  dropdown-toggle form-control"
                  data-bs-toggle="dropdown"
                  type="text"
                  placeholder="Select..."
                  readOnly
                />
                <p>Activity: </p>
                <ArrowCircleDown
                  className="arrow-down-icon"
                  size="21"
                  color="#64748b"
                />

                <ul className="dropdown-menu">
                  <li
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setForm("Last 30 Days");
                    }}
                  >
                    Last 30 Days
                  </li>
                  <li
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setForm("Last 45 Days");
                    }}
                  >
                    Last 45 Days
                  </li>
                  <li
                    onClick={() => {
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                      setForm("Last 60 Days");
                    }}
                  >
                    Last 60 Days
                  </li>
                  <li data-bs-toggle="modal" data-bs-target="#myModal3">
                    Custom
                  </li>
                </ul>
              </div>

              <div className="patient-search position-relative ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Patient..."
                />
                <SearchNormal1
                  size="21"
                  color="#64748b"
                  className="search-icon"
                />
              </div>
            </div>

            <div className="gap-3 d-flex align-items-center">
              <MoreCircle
                className="cursor-pointer"
                color="#facc15"
                variant="Bold"
                size={24}
                onClick={notify}
              />

              <Link to={"/AddPatient"} className="btn">
                + Add Patient
              </Link>
            </div>
          </div>

          <div className="col-12 info-card-col mb-32">
            <ul className="d-flex align-items-center gap-3">
              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <circle
                        cx="12"
                        cy="12.5"
                        r="9"
                        fill="#3B82F6"
                        stroke="#EFF6FF"
                        strokeWidth="6"
                      />
                    </svg>
                  </span>

                  <div className=" text-start ps-3">
                    <h4 className="mb-0">7</h4>
                    <p className="slate-500 mb-0">Patients</p>
                  </div>
                </div>
              </li>
              <li className="">
                <div className="d-flex info-card gap-3  bg-white align-items-center">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <circle
                        cx="12"
                        cy="12.5"
                        r="9"
                        fill="#84CC16"
                        stroke="#ECFCCB"
                        strokeWidth="6"
                      />
                    </svg>
                  </span>

                  <div className="text-start ps-3">
                    <h4 className="mb-0">4</h4>
                    <p className="slate-500 mb-0 ">Improving</p>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="d-flex info-card gap-3 bg-white align-items-center">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <circle
                        cx="12"
                        cy="12.5"
                        r="9"
                        fill="#EF4444"
                        stroke="#FEE2E2"
                        strokeWidth="6"
                      />
                    </svg>
                  </span>

                  <div className=" text-start ps-3">
                    <h4 className="mb-0">3</h4>
                    <p className="slate-500 mb-0">Not Improving</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-12 table-col">
            <div className="table-data-col bg-white table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="">Patients Name</th>
                    <th>Last Check In</th>
                    <th>Sessions Completed</th>
                    <th></th>
                    <th>Baseline Change</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, index) => (
                    <tr key={index}>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        {data.patientName}
                      </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        {data.lastCheckIn}
                      </td>

                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        {data.sessionsCompleted}
                      </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        {data.assessmentsDays}
                      </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        <span
                          className={`${
                            data.improve === true
                              ? "improve-badge"
                              : data.notImprove === true && "not-improve"
                          }`}
                        >
                          {data.baselineChange}
                        </span>
                      </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        <div className="add-ellipses">{data.note}</div>
                      </td>
                      <td data-bs-toggle="modal" data-bs-target="#myModal2">
                        <span
                          className="cursor-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                        >
                          <MoreCircle size="32" color="#64748b" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-12 pagination-col d-flex justify-content-end  my-4">
            <ul class="pagination pagination-sm ">
              <li class="page-item">
                <a class="page-link" href="#">
                  <ArrowLeft size={14} color="#020617" />
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  1
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  2
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  3
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  <ArrowRight size={14} color="#020617" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="modal portal-option-modal fade" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-0">
              <ul>
                <li>
                  <Link
                    to="/PatientProfile"
                    onClick={() => urlHandler("PatientProfile")}
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center justify-content-between"
                  >
                    <p className="mb-0">Profile</p>
                    <img
                      className="img-fluid"
                      src="../../assets/images/arrow-icon.svg"
                      alt="arrow-cicon"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ExerciseProgram"
                    onClick={() => urlHandler("ExerciseProgram")}
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center justify-content-between"
                  >
                    <p className="mb-0">Home Exercise Program Assignment</p>
                    <img
                      className="img-fluid"
                      src="../../assets/images/arrow-icon.svg"
                      alt="arrow-cicon"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/PatientProfile"
                    onClick={() => urlHandler("PatientPerformance")}
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center justify-content-between"
                  >
                    <p className="mb-0">Patient Peformance</p>
                    <img
                      className="img-fluid"
                      src="../../assets/images/arrow-icon.svg"
                      alt="arrow-cicon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="modal chartmodal" id="myModal2">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="chartmodal-col">
              <div className="chart-modal-title d-flex align-items-start justify-content-between">
                <div className="text-start">
                  <h5 className="mb-1 font-regular">
                    Plot of Home Exercise Program
                  </h5>
                  <p className="mb-0">Last 60 Days</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <ellipse
                    cx="12"
                    cy="12.0945"
                    rx="12"
                    ry="12.0284"
                    fill="#BDBDBD"
                  />
                  <rect
                    x="10"
                    y="9.42151"
                    width="3.99999"
                    height="10.6919"
                    rx="2"
                    fill="white"
                  />
                  <rect
                    x="10"
                    y="4.07556"
                    width="3.99999"
                    height="4.00945"
                    rx="2"
                    fill="white"
                  />
                </svg>
              </div>
              <Chart />
            </div>
          </div>
        </div>
      </div>

      <div className="modal " id="myModal3">
        <div className="modal-dialog calendar-modal-col modal-dialog-centered">
          <div className="modal-content ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-left" theme="light" />
      {/* <RefreshModal /> */}
    </>
  );
}
