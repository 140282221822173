import {
  ChartCircle,
  Home3,
  Profile2User,
  Setting,
  User,
  Weight
} from "iconsax-react";
import React, { useState ,useEffect } from "react";
import { Link } from "react-router-dom";
import { Triangle } from  'react-loader-spinner'



export default function Sidebar() {
  const [navActive, setNavActive] = useState("active");
  const [loader ,setLoader] = useState(false)



  const navActiveHandler = (active) => {
    switch (active) {
      case "Dashboard":
        setNavActive("active");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;
      case "Companies":
        setNavActive("active2");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;
      case "influencer":
        setNavActive("active3");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;
      case "Candidate":
        setNavActive("active4");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;
      case "Settings": 
        setNavActive("active5");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;
        case "Profile": 
        setNavActive("active6");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;

        
      case "Logout":
        setNavActive("active");
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
        }, 2000);
        // document.body.classList.remove("menu-show");
        break;
      default:
        break;
    }


    
  };

  return (
    <>
    {loader && 
    <div className="loader d-flex align-items-center justify-content-center ">
      <Triangle
  height="80"
  width="80"
  color="#facc15"
  ariaLabel="triangle-loading"
  wrapperStyle={{}}
  wrapperClassName=""
  visible={true}
/>
 
</div>
}

      <div className="siderbar navbar-collapse collapse" id="collapsibleNavbar">
        <div className="sidebar-col text-white">
          <ul className="">
            <li>
              <Link
                onClick={() => navActiveHandler("Dashboard")}
                to="/"
                className={`d-flex align-items-center justify-content-center flex-wrap ${navActive}`}
              >
                <Home3
                  size="28"
                  color={navActive === "active" ? "#3B82F6" : "#64748b"}
                  variant={navActive === "active" ? "Bold" : "Outline"}
                />
               
                {navActive === "active" &&
                <span className="active-border"></span>
                }
              </Link>
            </li>
            <li>
              <Link
                onClick={() => navActiveHandler("Companies")}
                to="/Patient"
                className={`d-flex align-items-center justify-content-center flex-wrap ${navActive}`}
              >
                <Profile2User
                  size="28"
                  color={navActive === "active2" ? "#3B82F6" : "#64748b"}
                  variant={navActive === "active2" ? "Bold" : "Outline"}
                />
                {navActive === "active2" &&
                <span className="active-border"></span>
                }
              </Link>
            </li>

            <li>
              <Link
                onClick={() => navActiveHandler("Profile")}
                to="/"
                className={`d-flex align-items-center justify-content-center border-top mx-2 flex-wrap ${navActive}`}
              >
                <User
                  size="28"
                  color={navActive === "active6" ? "#3B82F6" : "#64748b"}
                  variant={navActive === "active6" ? "Bold" : "Outline"}
                />
                {navActive === "active6" &&
                <span className="active-border"></span>
                }
              </Link>
            </li>

            <li>
              <Link
                onClick={() => navActiveHandler("influencer")}
                to="/"
                className={`d-flex align-items-center justify-content-center flex-wrap ${navActive}`}
              >
                <Weight
                  size="28"
                  color={navActive === "active3" ? "#3B82F6" : "#64748b"}
                  variant={navActive === "active3" ? "Bold" : "Outline"}
                />
                {navActive === "active3" &&
                <span className="active-border"></span>
                }
              </Link>
            </li>

            <li>
              <Link
                onClick={() => navActiveHandler("Candidate")}
                to="/"
                className={`d-flex align-items-center justify-content-center border-bottom mx-2 flex-wrap ${navActive}`}
              >
                <ChartCircle
                  size="28"
                  color={navActive === "active4" ? "#3B82F6" : "#64748b"}
                  variant={navActive === "active4" ? "Bold" : "Outline"}
                />
                {navActive === "active4" &&
                <span className="active-border"></span>
                }
              </Link>
            </li>

            <li>
              <Link
                onClick={() => navActiveHandler("Settings")}
                to="/Settings"
                className={`d-flex align-items-center justify-content-center flex-wrap ${navActive}`}
              >
                <Setting
                  size="28"
                  color={navActive === "active5" ? "#3B82F6" : "#64748b"}
                  variant={navActive === "active5" ? "Bold" : "Outline"}
                />
                {navActive === "active5" &&
                <span className="active-border"></span>
                }
              </Link>
            </li>

            
          </ul>
        </div>
      </div>
    </>
  );
}
