import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { Star1 } from 'iconsax-react'
const style = {
  cursor: 'move',
}
export const Card = memo(function Card({ key, id, cardData, moveCard , deleteCard , ratingHandler }) {
  const ref = useRef(null)
  const [{ isDragging, handlerId }, connectDrag] = useDrag({
    type: ItemTypes.CARD,
    item: { id },
    collect: (monitor) => {
      const result = {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      }
      return result
    },
  })
  const [, connectDrop] = useDrop({
    accept: ItemTypes.CARD,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        moveCard(draggedId, id)
      }
    },
  })
  connectDrag(ref)
  connectDrop(ref)
  const opacity = isDragging ? 0 : 1
  const containerStyle = useMemo(() => ({ ...style, opacity }), [opacity])


  const [arr, setArray] = useState([]);
  const [cardData1,setCardData1]=useState(cardData)
  const [selected, setSelected] = useState([]);

  useEffect(()=>{
    debugger
    setCardData1(cardData)

  },[cardData]) 
 
  // const
  const [setsData , setSetsData] = useState([
    {
      id: 1,
      setName: "Feet Together",
      setStatus: "dynamic",
      url: "../../assets/images/exercise2.svg",
      setSide: false,
      setLeftSide: "L",
      setRightSide: "R",
      rating: false
    },
    {
      id: 2,
      setName: "Tandem Walk",
      setStatus: "dynamic",
      url: "../../assets/images/exercise2.svg",
      setSide: false,
      setLeftSide: "L",
      setRightSide: "R",
      rating: false
    },
    {
      id: 3,
      setName: "Single Leg SR Hold",
      setStatus: "static",
      url: "../../assets/images/exercise3.svg",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      rating: false
    },
    {
      id: 4,
      setName: "Back Leg Raises",
      setStatus: "static",
      url: "../../assets/images/exercise4.svg",
      setSide: true,
      setLeftSide: "L",
      setRightSide: "R",
      rating: false
    },
  ]);

  let array = [];
  const selectCardHandler = (id) => {
    const indexNo = selected.indexOf(id);
    if (indexNo > -1) {
      const data = [...selected];
      setSelected(data.splice(indexNo, 1)); // 2nd parameter means remove one item only
      setSelected(data);
    } else {
      setSelected([...selected, id]);
      // setArray([...arr , setsData[index]])
    }

    // console.log(data, "checking data");
    // else{
    //   setSelected([...selected , index]);
    //   console.log(selected ,'selected')

    // }
    // setSelectedCard("selected-exercise")

    console.log("array", array);
  };
  const submithandler = () => {
    const data = setsData?.filter((value) => selected.includes(value.id));
    setArray(data);
  };
  const handleDeleteClick = (id) => {
    deleteCard(id);
  };

  const setRatingHandler = (id) => {
    ratingHandler(id)
  }
  console.log(cardData, "ddddddddddddddddddddddd")
  return (

   
    <div key={key} className="col-12 col-lg-4 col-xl-3 addexercise-card mb-32" ref={ref} style={containerStyle} data-handler-id={handlerId}>
   
              <div className="addexercise-info bg-white">
                <div className="text-start exercise-title mb-4 d-flex gap-3">
                  <img
                    className="img-fluid"
                    src="../../assets/images/drag-icon.svg"
                    alt="icon"
                  />
                  <div>
                    <h6 className="mb-0">{cardData.setName}</h6>
                    <p
                      className="mb-0 text-capitalize "
                      style={{
                        color:
                          cardData.setStatus === "dynamic"
                            ? "#EF4444"
                            : "#84CC16",
                      }}
                    >
                      {cardData.setStatus}
                    </p>
                  </div>
                  <div className='d-flex align-items-center justify-content-end  gap-2'>
                  <Star1 className={`cursor-pointer `} color={`${cardData1.rating === true ? "#facc15": "#64748b"}`} size={20, 20} variant="Bold" onClick={()=> setRatingHandler(cardData.id)}/>
                  <img
                  onClick={()=>handleDeleteClick(cardData.id)}
                    className="img-fluid cursor-pointer"
                    src="../../assets/images/close-icon.svg"
                    alt="icon"
                  />
                 
                  </div>
                </div>

                <img className="img-fluid mb-4" src={cardData.url} alt="icon" />

                <div className="reps-col position-relative mb-3">
                  <input
                    className="form-control"
                    placeholder="0"
                    type="number"
                  />
                  <span className="slate-500">Reps per session</span>
                </div>

                <div className="per-day-col d-flex">
                  <div className="position-relative w-50">
                    <input
                      className="form-control ps-0"
                      placeholder="0"
                      type="number"
                    />
                    <span className="slate-500">X per</span>
                  </div>

                  <select
                    class="form-select form-control pe-0 w-50 border-0"
                    id="sel1"
                    name="sellist1"
                  >
                    <option selected disabled>
                      Days
                    </option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
            </div>
  
  )
})
    


// export default Card;