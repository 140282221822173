
import update from 'immutability-helper'
import { Component } from 'react'
import { Card } from './card.js'
// const style = {
//   width: 400,
// }
export function buildCardData(setsData) {

  const cardsById = {}
  const cardsByIndex = []
  // for (let i = 0; i < 4; i += 1) {
    setsData?.forEach(cardd => {
    const card = { id: cardd.id, text: cardd}
    cardsById[card.id] = card
    cardsByIndex[card.id] = card
  })
  return {
    cardsById,
    cardsByIndex,
  }
}
export class Container extends Component {
  requestedFrame
  cardState = buildCardData(this.props.setsData)
  constructor(props) {
    super(props)
    this.state = STATE
  }
  componentWillUnmount() {
    if (this.requestedFrame !== undefined) {
      cancelAnimationFrame(this.requestedFrame)
    }
  }

  render() {
    const { cardsByIndex } = this.cardState
  
    return (
   
      <>
          {cardsByIndex.map((card) => (
            card && card.text &&
            <Card
              key={card.id}
              id={card.id}
              cardData={card.text}
              moveCard={this.moveCard}
              deleteCard={this.deleteCard}
              ratingHandler={this.handleRating}
            />
            ))}
     </>
  
    )
  }

  handleRating = (id) => {
    this.props.ratingHandler(id)
    
      // // alert("faiq")
      // // debugger
      // console.log(id, "new")
      // const { cardsById, cardsByIndex } = this.cardState;
      //  const cloneDataSet =[...cardsByIndex];
      // const index= cloneDataSet.findIndex( (item) => item.id === id)
      // cloneDataSet[index].rating = !cloneDataSet[index].rating
  
      // this.cardState = {
      //   cardsById: { ...cardsById },
      //   // cardsByIndex: [...cardsByIndex]
      //   cardsByIndex: cloneDataSet
      // };
    
    this.scheduleUpdate();
  };

  deleteCard = (id) => {
    console.log(id, "111")
    const { cardsById, cardsByIndex } = this.cardState;

    // Remove the card by id
    delete cardsById[id];
    // cardsByIndex.splice(id, 1);
    const updatedCardsByIndex = cardsByIndex.filter(card => card.id !== id);

    this.cardState = {
      cardsById: { ...cardsById },
      // cardsByIndex: [...cardsByIndex]
      cardsByIndex: updatedCardsByIndex
    };

    this.scheduleUpdate();
  };
  moveCard = (id, afterId) => {
    const { cardsById, cardsByIndex } = this.cardState
    const card = cardsById[id]
    const afterCard = cardsById[afterId]
    const cardIndex = cardsByIndex.indexOf(card)
    const afterIndex = cardsByIndex.indexOf(afterCard)
    this.cardState = update(this.cardState, {
      cardsByIndex: {
        $splice: [
          [cardIndex, 1],
          [afterIndex, 0, card],
        ],
      },
    })
    this.scheduleUpdate()
  }
  scheduleUpdate() {
    if (!this.requestedFrame) {
      this.requestedFrame = requestAnimationFrame(this.drawFrame)
    }
  }
  drawFrame = () => {
    this.setState(STATE)
    this.requestedFrame = undefined
  }
}
const STATE = {}
